body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}


.container-fluid {
  background-color: black;

  min-height: 100vh;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  font-family: 'cpxawefont', sans-serif;
}
.video{
  position:relative;
  padding-bottom: 75.25%;
  padding-top: 0;
  height: 0 ;
  top:1.8vw;
}

.video iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

}

.zoom-video{
  position:relative;
  padding-bottom: 56%;
  padding-top: 0;
  height: 0 ;
  top:1.8vw;
}

.zoom-video iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

}
@font-face {
  font-family: "cpxawefont";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/571240/cpxawefont.ttf");
  /* TTF file for CSS3 browsers */
}

.learnMoreContainer{

  height: 70px;

  margin-left: auto;
  margin-right: auto;
  padding-top: 2vh;
  background-color: #1b1e21;
  opacity:0.7
}
.learnMoreContainer a, .learnMoreContainer a:hover{
  color: #ffff15;
  font-size: x-large;
  text-underline: none;
}


.introductionContainer{
  padding: 1vh;

}
.homeMessageContainer{
  font-size: 1.25rem;
  color: #fff;
  margin: 10px auto;
}

.checkoutContainer{
  font-size: 1.25rem;
  color: #fff;
  margin: 10px auto;
  padding: 20px;
}
.homeMessageContainer table{
  margin:40px auto 20px auto;
}
.homeMessageContainer tr{
  vertical-align:top;
}

.homeMessageContainer td{
  vertical-align: middle;
  text-align:left;
}

.homeMessageContainer h2{
  font-size: 0.8rem;
  color: #1fcf0a;
  font-weight: normal;
}
.homeMessageContainer img{
  height:2.95rem;
}
.homeMessageContainer img.connect{
  width:12rem;
  margin: 5px 5px 0 0;

}

.homeMessageContainer a, .homeMessageContainer a:hover{
  color: #ffff15;
  font-weight: normal;
  font-size: 1vw;
}
.homeMessageContainer .clientName{
  font-size:2rem
}
.homeMessageContainer .bullet{
  font-size: 2em;
  padding-right: 20px;
}

.subscriptionsContainer {
  font-size: 1rem;
}
.subscriptionsContainer h3{
  font-size: 1.3rem;
}

.interval-timer{
  font-size: 2vw;
  color: #ffffff;
  background-color: #1FCF0A;
  font-weight: bold;
  width: 10vw;
  margin: auto;

}

.workout-timer{
  font-size: 1.5vw;
  color: #fff;

}

.stepIndicator{
  font-size: 1.5vw;
  line-height: 2vw;
}

.gradeText {
  font-size: 0.6vw;
  color:#fff ;
}
@keyframes blink {
  0% { color: #FFFF15; }
  100% { color: black; }
}
@-webkit-keyframes blink {
  0% { color: #FFFF15; }
  100% { color: black; }
}
.workoutStatusMessage{
  font-size: 1.1vw;
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}

.coachMessage{
  font-size: 1.2vw;
  color: #fff;
}


.stepIndicator{
  font-size: 2vw;
  line-height: 2vw;

}
.classStatsContainer{
  overflow-x: auto;
  position: absolute;
  bottom:23vh;
  left: 1vw;
  height: 12vh;
  width: 44vw;
}

.currentClassMetrics{
  min-width:12.5vw;
}
.shadowContainer {
  shadow-opacity: 0.1;
  shadowRadius: 15;
  shadow-radius: #000000;

  shadow-offset:0px 0px  ;
  background-color: #212220;
  border-radius: 20px;
  margin-right: 20px;
}

.homeMessageContainer .nav.nav-dark{
  border: 0px solid black;
  background-color: #212220;
  border-bottom-width: 2px;
  border-color: #cccccc;

}
.homeMessageContainer .nav{
  border-radius: 3px;
  padding: 0 5px;
}
.homeMessageContainer .nav.nav-dark a{
  color: #2F8F00;
}
.homeMessageContainer .nav.nav-dark a.active{
  border: 0px solid black;
  border-bottom-width: 4px;
  border-color: #2F8F00;
  font-weight: bold;
}
.homeMessageContainer .shadowContainer{
  border-radius: 3px;
}

.tab-content{
  background-color: #212220;
  border-radius: 3px;
  min-height: 200px;
}



.headerRow {
  shadow-opacity: 0.1;
  shadowRadius: 15;
  shadow-radius: #000000;

  shadow-offset:0px 0px  ;
  background-color: #212220;
  border-radius: 20px;
  margin-right: 20px;

}


.extendedClassStatsContainer{

  overflow-y: auto;
  overflow-x: auto;
  height: 30vh;

}

.remoteGaugeContainer {
 /* min-width: 10.35vw;*/

}
.remoteGaugeContainer h4{
 font-size: 0.8vw;
}

.videoContainer{
  width: 40vw !important;
}
.zoomVideoContainer{
  width: 56vw !important;
}

.gaugeContainer{
  width: 23vw !important;
}

.metricsContainer{
  width: 14vw !important;
  height: 55vh;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top:1.5vw;
  margin-bottom:1.5vw;

}


.sessionsList{
  height: 50vh;
  overflow-y: auto;

}

.introductionList{
  font-size: 1rem;
  /*height: 29vh;
  overflow-y: auto;*/
}

.workoutNoteModal{
  max-width: 80% !important;
  height: 85vh;
  overflow-y: auto;
}

.subscriptionExpiredModal{
  height: 85vh;
}

.metricsContainerLarge{
   height: 59vh;
  overflow: auto;

}
.classMetricsTable {
  font-size: 1.2vw;
  line-height:1.5vw;

}
.introductionContainer h4 , .introductionContainer h5{
  font-size: 1.1vw;
}

.introductionContainer .card-title.h5{
  font-size: 1.1vw;
}
.introductionContainer .card-body{
  padding: 0;
}
.classMetricsTable td, .classMetricsTable th{
  padding: 0.35rem;
  min-width: 5rem;
  border-left: 1px solid #dee2e6;
}
.classMetricsTable , .table-dark > th, .table-dark > td {
  background-color: #212220  !important;
}
td.largeDigits{
  font-size: 1.5vw;
  line-height: 0.5;
}
td.smallLabel{
  font-size: 0.7vw;
  line-height: 0.2vw;

}

.form-inline label {
  padding: 0.7vw;
  color: #fff;
  font-size: 1.5rem;
}

.metricsTable {
  margin-right: 4vw;
  margin-bottom: 20px;
  color: #ccc;
  background-color: #212220;
  font-size: 1.2vw;
  line-height: 1.5vw

}

table.summaryTable {


  line-height: 1.5vw

}

table.summaryTable th, table.summaryTable td{
  color: #fff !important;
  font-size: 1.5vw;
}


 tr.currentSetHeader th {
  position: sticky;
  top: -1px;
  width: 100%;
  background-color: #212220;
  margin-top: 0;
}

.metricsTable , .table-dark > th, .table-dark > td {
   background-color: #212220  !important;
 }


/*
.metricsTable .table-striped tbody tr:nth-of-type(odd) {
  background-color: #000 !important;
}

 .table-striped tbody tr:nth-of-type(odd) {
   background-color: #000  !important;
 }
*/


.text-danger , .AboveTargetColor {
  color: #C01818 !important;
}
.text-primary, .BelowTargetColor {
  color: #3595F0 !important;
}
.text-success  ,.OnTargetColor {
  color: #2F8F00  !important;
}
.text-warning, .NextTargetColor, .HeaderColor {
  color: #FFFF15 !important;
}
.card{
  border-color: #212220  !important;
}
.card .card-body, .list-group-item{
  background-color: #212220 !important;
  color: #ccc !important;
}

.card-img, .card-img-bottom, .card-img-top {
  flex-shrink: 0; height: 220px;
}

.card-title.h5 {
  font-size: 2rem;
  text-align:center;
  white-space: nowrap;
}
.list-group-item a{
  font-weight: bold;
  color: #2F8F00 !important;
}

.paymentSuccessContainer .subscription_name,
.subscriptionsContainer .list-group-item .subscription_name{
  font-weight: bold;
  color: #2F8F00 !important;
  text-align: left;
}

.paymentSuccessContainer .reference,
.subscriptionsContainer .list-group-item .expiration{
  font-size: 0.7rem;
  color: #fff !important;
  text-align: left;
  float:left;
}

.subscriptionsContainer .list-group-item .subscription_type{
  font-size: 0.9rem;
  color: #FFFF15 !important;
  text-align: right;
  clear:left;
}

.gameMetrics{
  white-space: nowrap;
}
 .rank {
   display: inline-block;
  font-size: 1.5vw;
   width: 2vw;
   float: left;
   vertical-align: middle;
   line-height: 1.5;
}

.gameStatistic{
  width: 2.8vh;
}


.classMetricsTable .rank{
  font-size: 1.2vw;
  width: 1vw;
  line-height: 2;
}
.classMetricsTable .gameStatistic{

  display: block;
  width: 3vw;
  text-align: center;
  text-wrap: none;

}


.tar{
  color: #fff;
  float: left;
  vertical-align: middle;
  text-align: center;
  line-height: 1.7;
}


.tiz{
  color: #7f007f;
  float: left;
  line-height: 1.7;
}


.spr{
  color: #31b845;
  float: left;
  line-height: 1.7;
}

.spr{
  color: #31b845;
  float: left;
  line-height: 2.5;
  font-size: 0.9vw;
}

.agr{
  color: #ff0000;
  float: left;
  line-height: 1.7;

}
.pwLogo{
  width: 50vh;
}

#root {
  width: 100%;
}

.PaymentForm form {
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
}

.PaymentForm input , .PaymentForm  select {
  background: #f9f9f9;
  padding: 10px;
  width: 100%;
  border: none;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  outline: none;
  font-weight: 300;
}

.PaymentForm .rccs__card--unknown > div {
  background: #555;
}


.paymentSuccessContainer  {
  background-color: #343a40;
  width: 45%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 10px;
}

.checkoutContainer h2{

  font-size: 0.8rem;
  color: #1fcf0a;
  font-weight: normal;
}

.checkoutContainer h3{
  margin: auto;
  padding: 10px;

}

.checkoutContainer img{

  height:2.95rem;

}

.unregisteredClass{
  background-color: #343a40 !important;
  font-style: italic;
}

.productList.row{
  padding: 25px;
  margin: auto;
  background-color: #ccc;

}
.productCard  {
  transition: all 1s ease-in-out;
  cursor: pointer
}
.productCard .uncheckedProduct:hover{
  transform: scale(1.05);

}
.productCard .checkedProduct{
  transform: scale(1.1);

}

.productCard .productName{
  font-size: 1.0rem;
  text-align: center;

}

.productCard .productPrice{
  font-size: 1.5rem;
  text-align: center;
  color: #FFFF15 !important;
}
.productCard .productInclTax{
  font-size: 0.9rem;
  text-align: center;
  color: #fff !important;

}
.productCard .productExpiration{
  font-size: 0.9rem;
  text-align: center;
  color: #999 !important;
  font-style: italic;

}
